@font-face {
    font-family: 'Interstate';
    src: url('../fonts/Interstatelight.woff2') format('woff2'),
         url('../fonts/Interstatelight.woff') format('woff'),
         url('../fonts/Interstatelight.ttf') format('truetype'),
         url('../fonts/Interstatelight.svg#Interstatelight') format('svg');
	font-style:normal;
	font-weight:300;
}

@font-face {
    font-family: 'Interstate';
    src: url('../fonts/Interstateregular.woff2') format('woff2'),
         url('../fonts/Interstateregular.woff') format('woff'),
         url('../fonts/Interstateregular.ttf') format('truetype'),
         url('../fonts/Interstateregular.svg#Interstateregular') format('svg');
	font-style:normal;
	font-weight:400;
}
@font-face {
    font-family: 'Interstate';
    src: url('../fonts/Interstatebold.woff2') format('woff2'),
         url('../fonts/Interstatebold.woff') format('woff'),
         url('../fonts/Interstatebold.ttf') format('truetype'),
         url('../fonts/Interstatebold.svg#Interstatebold') format('svg');
	font-style:normal;
	font-weight:700;
}
body {
    font-family: 'Interstate', 'Roboto', sans-serif;
    line-height: 1.4;
    color: #212f3a;
    font-size: 16px;
    font-weight:300;
    /* overflow: hidden; */
}

img {
    max-width: 100%;
    height: auto;
}

.fa {
    font-family: FontAwesome !important;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #000 !important;
    font-size: 14px;
    font-weight: 500;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #000 !important;
    font-size: 14px;
    font-weight: 500;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #000 !important;
    font-size: 14px;
    font-weight: 500;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #000 !important;
    font-size: 14px;
    font-weight: 500;
}

a {
    color: #31c5d0;
}

a:focus, a:hover {
    color: #03386a;
    text-decoration: none;
}

p {
    margin-bottom: 1rem;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}
.text-defaultcase{
	text-transform:inherit !important;
}
.text-dark{
	color:#2f353a  !important;
}
.text-primary{
	color:#31c5d0  !important;
}
.bg-primary {
    background-color: #67c1c2;
    color: #fff;
}
.bg-light {
    background-color: #f5f5f5;
}
.bg-dark {
    background-color: #202e39;
}

.bg-primary a, .bg-dark a {
    color: #fff;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}
.mb-60 {
    margin-bottom: 60px !important;
}
.mb-80 {
    margin-bottom: 80px !important;
}
.mb-100 {
    margin-bottom: 100px !important;
}
.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-semibold {
    font-weight: 600 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-extrabold {
    font-weight: 900 !important;
}

.h1, .h2, .h3, .h4, h1, h2, h3, h4 {
    font-weight: 400;
}
.h1, h1{
	font-weight:400;
	line-height: 1;
	margin-bottom: 20px;
}
.h2, h2 {
    font-size: 24px;
}

.h3, h3 {
    font-size: 18px;
}

.header {
    font-weight: 600;
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 20px 0;
}

.navbar-toggler {
    border: 0;
}

.container, .container-fluid {
    padding: 0 30px;
}

.btn {
    padding: 10px 25px;
    font-weight: 300;
    font-size: 16px;
    border-radius: 0px;
    text-transform: uppercase;
    white-space: normal;
    font-weight: 400;
}

.btn-group-lg>.btn, .btn-lg {
    padding: 14px 40px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
}

.btn-primary, .navbar .navbar-nav > li.btn-cont > a.btn-primary {
    background-color: #31c5d0;
    border-color: #31c5d0;
    color: #fff;
    padding: 5px 21px;
}

.btn-outline-primary {
    color: #31c5d0;
    border-color: #31c5d0;
    border-width: 2px;
    background: inherit;
	outline:0;
}

.btn-outline-light {
    color: #fff;
    border-color: #fff;
    border-width: 1px;
}

.btn-primary:hover,.btn-primary:focus, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle, .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle,.navbar .navbar-nav > li.btn-cont > a.btn-primary:hover {
    background: #1ed1de;
    color: #fff;
    border-color: #1ed1de;
}

.btn-primary.focus, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus, .btn-outline-primary.focus, .btn-outline-primary:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-primary.dropdown-toggle:focus, .btn-outline-primary:active {
    box-shadow: 0 0 0 0.2rem rgba(30, 209, 222, 0.5);
}

.btn-outline-light:hover, .btn-outline-light:focus {
    color: #333;
    border-color: #fff;
    border-width: 1px;
    background-color: #fff;
}

.row.compact {
    margin-right: -5px;
    margin-left: -5px;
}

.row.compact > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.navbar-default {
    border: 0;
    background: rgba(32, 46, 57, 0);
    margin: 0;
}

.navbar-default .navbar-toggle {
    border: 0;
    margin-top: 18px;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background: none;
}

.navbar-brand {
    height: auto;
}

.navbar .navbar-nav {
    padding: 5px 0;
}

.navbar-brand {
    margin-right: 1.5rem;
    position: relative;
}

.logo {
    width: 158px;
}

.navbar .navbar-nav li a {
    color: #9da7b2;
    text-transform: uppercase;
    display: inline-block;
    font-size: 14px;
}

.navbar .navbar-nav li a :focus, .navbar .navbar-nav li a :hover {
    color: #000000;
}

.navbar .navbar-nav > li.language a {
    color: #0bbdfb;
}

.navbar .navbar-nav > li.language a:hover {
    color: #000;
}

.navbar .navbar-nav > li {
    border-bottom: 1px solid #e7e7e7;
    margin: 0 0 8px;
    padding: 0 0 8px;
}

.navbar .navbar-nav > li.language, .navbar .navbar-nav > li.btn-cont, .navbar .navbar-nav > li.resources {
    border: 0;
}

.navbar .navbar-nav > li.btn-cont {
    padding-left: 0;
}

.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
    color: #31c5d0;
    background-color: transparent;
}

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
    background: none;
    color: #333;
}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover {
    background: none;
}

.navbar .navbar-nav>li>.dropdown-menu a {
    padding: 8px 25px;
    display: block;
}


.banner {
    position: relative;
    z-index: 0;
    min-height: 300px;
    background-size: cover;
    padding: 80px 0px 60px;
    background-color: #202e39;
    color: #fff;
    line-height: 1.3;
}
.banner-figure {
    margin: 0 -30px -60px;
    background-size:cover;
    background-position:50% 50%;
    background-color: #f2f1f5;
    background-image:url('../images/banner.jpg');
    min-height: 200px;
}
.banner-figure img, .banner-figure iframe, .banner-figure video{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.bar-style {
    position: relative;
    padding: 0 0 0 50px;
}

.bar-style:before {
    content: '';
    display: block;
    width: 40px;
    height: 25px;
    background: #2eb6b6;
    position: absolute;
    left: 0px;
    top: 6px;
}
.sec{
	padding:20px 0;
}
.form-control {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid #202e39;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 6px 0px;
    font-size: 16px;
    text-indent: 0;
    color: #000;
    font-weight: 300;
    background: rgba(255, 255, 255, 0);
}

.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #31c5d0;
}

label {
    font-weight: 400;
}

.form-field label {
    margin: 0;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    vertical-align: middle;
}

.form-field .field {
    display: block;
}


.form-field {
    margin: 0 0 40px;
}
.form-field-radios li:after {
    content: "/";
    font-size: 28px;
    font-weight: 300;
    margin: 0 15px;
    line-height: 1;
    padding: 0px 0 0 10px;
    display: inline-block;
    vertical-align: middle;
}

.form-field-radios li:last-child:after {
    content: '';
    margin: 0;
}

/*footer*/
.footer {
    background: #202e39;
    position: relative;
    z-index: 0;
    padding: 50px 0px;
    background-size: cover;
    color: #fff;
    font-weight: 300;
    font-size: 16px;
    overflow: hidden;
}


.site-info {
}

.footer h3 {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 10px;
    color: #848484;
    margin-top: 0;
}

.footer a {
    color: #fff;
}

.footer a:hover {
    color: #31c5d0;
}
.footer ul li a:hover {
    color: #31c5d0;
    border-color: #fff;
    text-decoration: none;
}

.footer input::-webkit-input-placeholder {
    color: #fff !important;
}

.footer input:-ms-input-placeholder {
    color: #fff !important;
}

.footer input::placeholder {
    color: #fff !important;
}
.footer input:focus::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.3) !important;
}
.footer input:focus:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3) !important;
}
.footer input:focus::placeholder {
    color: rgba(255, 255, 255, 0.3) !important;
}

.footer ul li {
    margin-bottom: 4px;
}

.footer-social {
    padding: 25px 0 0;
}

.footer .menu {
    list-style: none;
    padding: 20px 0 30px;
}

.footer .menu li {
    display: inline-block;
    margin: 0 4px 15px;
}

.footer .menu li a {
    font-size: 15px;
    text-transform: uppercase;
    padding: 4px 9px;
    border: 1px solid #fff0;
    display: block;
    text-decoration: none;
}

.footer .menu li a:hover {
    color: #fff;
    border-color: #fff;
}

.footer .menu li.active a {
    color: #31c5d0;
    border-color: #31c5d0;
}

.footer-social ul li a {
    font-size: 24px;
    color: #fff;
    border: 0;
    padding: 5px;
}
.logo-footer {
    text-align: left;
}

.logo-footer img {
    width: 165px;
    display: block;
}

.logo-footer .slogan {
    display: block;
    font-size: 16px;
    padding: 7px 0 0;
}
.footer .form-control {
    background: none;
    border-color: #fff;
    color: #fff;
}
.signup .input-group-addon {
    padding: 0;
    background: none;
    border: 0;
    border-bottom: 1px solid #fff;
    position: relative;
    border-radius: 0;
}

.signup .input-group-addon [type="submit"] {
    width: 60px;
    border:0;
    background:none !important;
    text-align: right;
    padding: 0;
    font-size: 0;
	background:inherit;
    height: 32px;
    outline: 0;
}
.signup .input-group-addon [type="submit"] .st0{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
.signup .input-group-addon [type="submit"] .st1{fill:#FFFFFF;}
.signup .input-group-addon [type="submit"]:hover, .signup .input-group-addon [type="submit"]:active, .signup .input-group-addon [type="submit"]:focus{
	background:inherit;
	-webkit-box-shadow:inherit !important;
	box-shadow:inherit !important;
	outline:0;
}
.signup .input-group-addon [type="submit"]:hover .st0{stroke:#31c5d0;}
.signup .input-group-addon [type="submit"]:hover .st1{fill:#31c5d0;}
.mx-auto{
	float:none;
	margin:0 auto;
}
.form-field-radios{
	margin:0;
}


.wpcf7-checkbox label input[type="checkbox"],.custom-checkbox input[type="checkbox"],.custom-radio input[type="radio"] {
    position: absolute;
    left: 5px;
    top: 2px;
    z-index: 3;
    opacity: 0;
    visibility: hidden
}

.wpcf7-checkbox label input[type="checkbox"] + span,.custom-checkbox input[type="checkbox"] + span,.custom-radio input[type="radio"] + span {
    position: relative;
    padding: 3px 35px  0 0;
    margin: 0;
    font-size: 16.5px;
}

.wpcf7-checkbox label input[type="checkbox"] + span:before,.custom-checkbox input[type="checkbox"] + span:before,.custom-radio input[type="radio"] + span:before {
    content: '';
    width: 24px;
    height: 24px;
    border: solid 1px #212f3a;
    background-color: #fff;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
}

.wpcf7-checkbox label input[type="checkbox"] + span:after,.custom-checkbox input[type="checkbox"] + span:after,.custom-radio input[type="radio"] + span:after {
    content: '';
    display: inline-block;
    position: absolute;
    right: 6px;
    z-index: 3;
    top: 7px;
    opacity: 0;
    visibility: hidden;
    color: #fff;
    width: 10px;
    height: 10px;
    background-size: 100%;
    background: #31c5d0;
    border-radius: 50%;
}

.wpcf7-checkbox label input[type="checkbox"]:checked + span:before,.custom-checkbox input[type="checkbox"]:checked + span:before,.custom-radio input[type="radio"]:checked + span:before {
    background: #fff;
}

.wpcf7-checkbox label input[type="checkbox"]:checked + span:after,.custom-checkbox input[type="checkbox"]:checked + span:after,.custom-radio input[type="radio"]:checked + span:after {
    opacity: 1;
    visibility: visible
}

.custom-radio, .custom-checkbox{
	position:relative;
}
.custom-radio input[type="radio"] + span, .custom-checkbox input[type="checkbox"] + span {
	display:inline-block
}

.custom-radio input[type="radio"] + span:before {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    right: -1px;
}
.sec-graph {
	margin-top: 100px;
}
.cta {
    padding: 40px 20px;
    text-align: center;
    margin: -120px 0 40px;
}

.cta strong {
    color: #212f3a;
    font-weight: 400;
}

.cta h3 {
    font-size: 26px;
    line-height: 1.2;
    margin: 0 0 30px;
}

.cta h3 strong {
}

.cta .price {
    font-size: 38px;
    background: #212f3a;
    padding: 15px 20px;
    line-height: 1;
    display: block;
    max-width: 300px;
    margin: 0 auto;
}
.ui-selectmenu-button.ui-button {
    display: block;
    width: 100%;
    background: #fff;
    border: 0;
    border-bottom: 1px solid #202e39;
    border-radius: 0;
    padding: 12px 0px 0;
    font-size: 14px;
    height: 34px;
    outline: 0;
}

.ui-widget.ui-widget-content {
    border: 0;
    border-bottom: 1px solid #ebebeb;
    padding: 0px 0px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.15);
	max-height:400px;
}

.ui-menu .ui-menu-item {
    border-bottom: 1px solid #ebebeb;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ui-menu .ui-menu-item > div {
   padding: 12px 20px;
   font-size: 14px;
}

.ui-menu .ui-menu-item:last-child {
    border-bottom: 0;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
    background: #2eb6b6;
    border-color: #2eb6b6;
}
.ui-menu .ui-menu-item:first-child {
    display: none;
}
/* @media START */
@media (min-width: 375px) {
    .bar-style {
    padding: 0 0 0 60px;
    }

    .banner .content h1 {
    }
    .banner-figure{

min-height: 230px;

}
}

@media (min-width: 410px) {
    .banner .content h1 {
    }
	.banner-figure{

min-height: 254px;
    }

}

@media (min-width: 480px) {
    .banner {
        padding: 90px 0px 60px;
    }
    .cta h3 {
		font-size: 30px;
		margin: 0;
	}
	.banner-figure{

    }
}

@media (min-width: 568px) and (max-width: 767px) {
   .col-xsm-12 {
    width: 100%;
  }
  .col-xsm-11 {
    width: 91.66666667%;
  }
  .col-xsm-10 {
    width: 83.33333333%;
  }
  .col-xsm-9 {
    width: 75%;
  }
  .col-xsm-8 {
    width: 66.66666667%;
  }
  .col-xsm-7 {
    width: 58.33333333%;
  }
  .col-xsm-6 {
    width: 50%;
  }
  .col-xsm-5 {
    width: 41.66666667%;
  }
  .col-xsm-4 {
    width: 33.33333333%;
  }
  .col-xsm-3 {
    width: 25%;
  }
  .col-xsm-2 {
    width: 16.66666667%;
  }
  .col-xsm-1 {
    width: 8.33333333%;
  }
  .footer .col-2{
	clear:right;
	}
	.footer .col-3{
		clear:left;
	}
	.banner-figure{

min-height: 400px;
    }
}

@media (min-width: 768px) {
    .h2, h2 {
        font-size: 36px;
    }
    .sec {
        padding: 50px 0;
    }
	.text-sm-left{
		text-align:left;
	}
	.banner-figure-cont {
		position: static;
	}
	.banner-figure {
		position: absolute;
		margin: 0;
		top: -90px;
		right: 0;
		bottom: 0;
		width: 48%;
	}

	.banner-figure img {
		display: none;
	}
	.form-field .form-control {
		padding: 10px 0px 0;
	}
	.form-field {
		margin: 0 0 50px;
	}
	.form-field label:not(.custom-radio) {
		float: left;
		padding: 11px 0 0;
	}
	.form-field-country label{
		width: 70px;
	}
	.form-field-country .field {
		margin: 0 0 0 70px;
	}
	.form-field-retirmentAge label{
		width: 135px;
	}
	.form-field-retirmentAge .field {
		margin: 0 0 0 135px;
	}
	.form-field-annuityPurchaseAge label{
		width: 210px;
	}
	.form-field-annuityPurchaseAge .field {
		margin: 0 0 0 210px;
	}
	.form-field-annualPaymentAmount label{
		width: 195px;
	}
	.form-field-annualPaymentAmount .field {
		margin: 0 0 0 195px;
	}
	.form-field-radios li:after {
		margin: 0 40px;
	}
	.sec2 .content p {
		padding: 0 50px;
	}
	.signup {
		max-width: 275px;
	}
	 .footer .col-2{
	clear:right;
	}
	.footer .col-3{
		clear:left;
	}
	.sec-graph{
		margin-top: 80px;
	}
	.cta{
		text-align:left;
		padding: 40px 40px;
	}
	.cta .price{
		float:right;
		width: 100%;
	}
}

@media (min-width: 992px) {
	.container, .container-fluid {
        padding: 0 15px;
    }
	.container-fluid{
		padding:0 60px;
		max-width: 1430px;
	}
	.header {
		padding: 40px 0;
	}
	.banner {
		padding: 120px 0px 60px;
	}
	.banner-figure {
		width: 56%;
	}
	.banner .content {
		max-width: 380px;
	}
	.h1, h1 {
		font-size: 44px;
	}
	.h2, h2 {
		font-size: 50px;
	}
	.bar-style {
		padding: 0 0 0 90px;
	}

	.bar-style:before {
		width: 73px;
		height: 40px;
	}
	.formCont{
		max-width:850px;
		margin:0 auto;
	}
	.sec2 .content p {
		padding: 0 70px;
	}
	.form-field-radios li:after {
		margin: 0 70px;
	}
	.footer .col-2, .footer .col-3{
		clear:inherit;
	}
	.footer{
		padding: 80px 0 80px;
	}
	.cta{
		padding: 56px 50px;
		margin: -150px 0 40px;
	}
	.cta h3 {
		font-size: 38px;
	}
}

@media (min-width: 1200px) {
	.banner {
		min-height: 530px;
		padding: 180px 0px 60px;
	}
	.footer{
		padding: 120px 0 80px;
	}
	.footer .col-1 {
		width: 31%;
	}

	.footer .col-2 {
		width: 35%;
	}

	.footer .col-3, .footer .col-4 {
		width: 17%;
	}
	.banner .content{
		margin:0 0 0 60px;
	}
}

@media (min-width: 1280px) {}


@media (min-width: 1440px) {
	.footer{
		padding: 180px 0 80px;
	}
	.cta {
		margin: -150px -15px 40px;
	}
	.banner-figure{
		width:60%;
	}
}

@media (min-width: 1600px) {

}


.error {
  color: red;
  font-size: 10px;
}
